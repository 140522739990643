export const flex_center = "flex items-center justify-center"

export const justify = {
  start: "flex justify-start",
  end: "flex justify-end",
  center: "flex justify-center",
  between: "flex justify-between",
  around: "flex justify-around",
  evenly: "flex justify-evenly",
}

export const align = {
  start: "flex items-start",
  end: "flex items-end",
  center: "flex items-center",
  baseline: "flex items-baseline",
  stretch: "flex items-stretch",
}
