import { useEffect, useState } from "react"
import { Modal, ModalProps } from "@components/modal"
import { setCookie } from "nookies"
import { calculateAge, domainChecker, isInAppChecker } from "@lib/functions"
import { RegularButton, GoogleLoginButton } from "@components/button"
import { FlexBox } from "@components/wrapper"
import AuthServices from "@services/auth.services"
import { FormInput } from "@components/input/react-hook-form/FormInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { loginValidationSchema } from "@modules/auth/const/validation"
import { useAnalytics } from "@lib/context/AnalyticsContext"

export interface LoginModalProps extends ModalProps {
  redirect?: string
}

export const LoginModal = ({ show, onClose, redirect, ...props }: LoginModalProps) => {
  const [validation, setValidation] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [validationRequired, setValidationRequired] = useState(false)
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    // @ts-ignore
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const { analytics } = useAnalytics()
  const isInApp = isInAppChecker()

  useEffect(() => {
    if (!show) {
      setValidation("")
    }
  }, [show])

  const auth = new AuthServices()

  function errorMessageGenerator(msg: string) {
    setValidation(msg)
  }

  const onSubmit = async (data: any) => {
    setValidation("")
    setIsLoading(true)
    try {
      // const captchaToken = await executeRecaptcha("enquiryFormSubmit")
      // const checkCaptcha = await google.checkCaptcha(captchaToken)

      // if (!checkCaptcha) {
      //   setIsLoading(false)
      //   return setValidation(
      //     "Terjadi kesalahan pada validasi. Silahan muat ulang page lalu coba lagi"
      //   )
      // }
      const res = await auth.manualLogin(data)

      if (res.isFailure) {
        const err = res.error
        setValidation(err.message)
        setIsLoading(false)
      } else {
        const result = res.getValue()
        analytics.track("Signed In", {
          method: "email",
        })

        if (!redirect) {
          window.location.reload()
        } else {
          window.location.assign(redirect)
        }

        
      }
    } catch (e: any) {
      setValidation("Terjadi kesalahan. Tunggu satu menit untuk mencoba ulang")
      setIsLoading(false)
    }
  }
  return (
    <Modal
      {...props}
      show={show}
      onClose={onClose}
      contentPadding="sm:p-9 p-5"
      contentClassName="sm:!max-w-[410px] w-full !rounded-none"
    >
      <div className="grid sm:gap-6 gap-4">
        {" "}
        <div>
          <h1 className="sm:text-3xl text-xl font-bold mb-1">Sudah Punya Akun?</h1>
          <p>Langsung masuk aja, ya!</p>
        </div>
        {!isInApp && (
          <>
            {" "}
            <GoogleLoginButton
              handleError={errorMessageGenerator}
              redirect={redirect}
              id="btn-login-with-google"
              copyText="Masuk dengan Google"
            />
            <div className="h-3 mb-2 text-center border-b-grey-ec border-b">
              <span className="bg-white px-3.5 sm-only:text-sm">atau</span>
            </div>
          </>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="grid sm:gap-6 gap-4">
          <div className="grid gap-4">
            <FormInput control={control} name="email" type="email" theme="blue" label="E-mail" />
            <FormInput
              control={control}
              name="password"
              type="password"
              theme="blue"
              label="Password"
            />
            <FlexBox justify="end">
              <a
                href={"/lupa-password"}
                className="font-semibold sm-only:text-sm text-new-green"
                id="btn-forget-password-modal"
              >
                Lupa Password?
              </a>
            </FlexBox>
          </div>

          <RegularButton
            className="w-full py-2"
            isSubmitting={isLoading}
            onClick={() => setValidationRequired(true)}
            isFormik
            id="btn-login-modal"
            variant="new-blue"
          >
            Masuk
          </RegularButton>
        </form>
        {/* Error Message */}
        {validation && <div className="text-red">{validation}</div>}
        <FlexBox justify="center" className="sm-only:text-sm">
          Belum punya akun?{" "}
          <a
            href={`/register`}
            className="ml-1.5 text-new-green font-semibold"
            id="btn-regist-modal"
          >
            Daftar sekarang
          </a>
        </FlexBox>
      </div>
    </Modal>
  )
}
