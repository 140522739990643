import * as Yup from "yup"

export const registerValidation = Yup.object()
  .shape({
    user_email: Yup.string().email("Email tidak valid").required("Wajib diisi"),
    user_fullname: Yup.string()
      .required("Wajib diisi")
      .min(3, "Nama minimal 3 karakter")
      .matches(/^[a-zA-Z\s]*$/, "Nama tidak boleh mengandung angka/karakter"),
    user_password: Yup.string()
      .required("Wajib diisi")
      .min(8, "Password minimal 8 karakter")
      .matches(/(?=.*[0-9])(?=.*[a-z])/, "Password salah harus kombinasi huruf dan angka"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("user_password")], "Password harus cocok")
      .required("Wajib diisi"),
  })
  .required()

export const biodataValidationSchema = Yup.object().shape({
  user_phone_number: Yup.string()
    .required("Wajib diisi")
    .min(10, "Minimal 10 karakter")
    .max(15, "Maksimal 13 karakter"),
  user_gender: Yup.number().required("*Wajib diisi").min(1, "Pilih salah satu"),
  user_birthday: Yup.date()
    .typeError("Wajib diisi")
    .max(new Date("2010-01-01"), "Minimal 17 tahun")
    .required("*Wajib Diisi"),
  user_interested_courses: Yup.array().min(1, "*Pilih minimal satu").required("*Wajib diisi"),
  user_password: Yup.string()
    .required("Wajib diisi")
    .min(8, "Password minimal 8 karakter")
    .matches(/(?=.*[0-9])(?=.*[a-z])/, "Password salah harus kombinasi huruf dan angka"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("user_password")], "Password harus cocok")
    .required("Wajib diisi"),
})

export const newUserValidationSchema = Yup.object().shape({
  user_phone_number: Yup.string()
    .required("*Wajib diisi")
    .min(12, "Pastikan kode negara di pilih, dan nomor telepon minimal 10 karakter")
    .max(15, "No. Telepon maksimal 13 karakter"),
  user_gender: Yup.number().required("*Wajib diisi").min(1, "Pilih salah satu"),
  user_birthday: Yup.date()
    .typeError("*Wajib diisi")
    .max(new Date("2010-01-01"), "Minimal 17 tahun")
    .required("*Wajib Diisi"),
  user_interested_courses: Yup.array().min(1, "*Pilih minimal satu").required("*Wajib diisi"),
  user_password: Yup.string()
    .required("*Wajib diisi")
    .min(8, "Password minimal 8 karakter")
    .matches(/(?=.*[0-9])(?=.*[a-z])/, "Password harus berisi kombinasi angka dan huruf"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("user_password")], "Password harus cocok")
    .required("*Wajib diisi"),
})

export const resetValidationSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("Password harus diisi")
    .min(8, "Password minimal 8 karakter")
    .matches(/(?=.*[0-9])(?=.*[a-z])/, "Password harus terdiri dari huruf dan angka"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("new_password")], "Password harus cocok")
    .required("Password harus diisi"),
})

export const loginValidationSchema = Yup.object()
  .shape({
    email: Yup.string().email("Email tidak valid").required("Wajib diisi"),
    password: Yup.string().required("Wajib diisi"),
  })
  .required()
