import { Spinner } from "../misc"
import React from "react"
import clsx from "clsx"
import { ButtonType } from "./types"

export interface RegularButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isSubmitting?: boolean
  disabled?: boolean
  isFormik?: boolean
  variant?: ButtonType
  textClassName?: string
  customPadding?: string
  customTextSize?: string
}

export const RegularButton = ({
  isSubmitting,
  textClassName,
  children,
  variant = "solid",
  customPadding = "px-5 py-2",
  customTextSize = "text-sm lg:text-base",
  className,
  isFormik,
  ...props
}: RegularButtonProps) => {
  let buttonStyle = ""

  switch (true) {
    case variant === "solid":
      buttonStyle = "text-white bg-green"
      break
    case variant === "transparent":
      buttonStyle = "text-green bg-transparent border-green border border-2"
      break
    case variant === "transparent-blue":
      buttonStyle = "text-new-blue bg-transparent border-new-blue border border-2"
      break
    case variant === "transparent-red":
      buttonStyle = "text-error bg-transparent border-error border border-2"
      break
    case variant === "transparent-white":
      buttonStyle = "text-white bg-transparent border-white border border-2"
      break
    case variant === "solid-blue":
      buttonStyle = "text-white bg-blue"
      break
    case variant === "new-blue":
      buttonStyle = "text-white bg-new-blue"
      break
    default:
      break
  }

  return (
    <button
      {...props}
      className={clsx(
        "relative flex items-center justify-center font-medium hover:shadow-[0_12px_32px_0px_rgba(0,0,0,0.2)]",
        "disabled:bg-grey-97 disabled:text-white disabled:cursor-not-allowed",
        buttonStyle,
        customPadding,
        customTextSize,
        className
      )}
      type={isFormik ? "submit" : "button"}
    >
      {isSubmitting && <Spinner size="sm" className="" />}
      <span className={clsx(isSubmitting && "text-transparent", textClassName)}>{children}</span>
    </button>
  )
}
