import clsx from "clsx"
import React, { useState } from "react"
import { useController } from "react-hook-form"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

interface Props extends React.InputHTMLAttributes<any> {
  control: any
  inputClassName?: string
  padding?: string
  name: string
  type: string
  theme: "green" | "blue"
  label: string
  placeholder?: string
}

export const FormInput = ({
  control,
  name,
  className,
  inputClassName,
  placeholder,
  padding,
  type,
  theme,
  label,
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const { field, fieldState } = useController({ name, control })
  const { error } = fieldState
  let inputStyle = ""
  switch (theme) {
    case "blue":
      inputStyle = "!focus:border-blue !active:border-blue"
      break

    default:
      inputStyle = "!focus:border-green !active:border-green"
      break
  }

  const passwordToggle = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div
      className={clsx(
        "material_input relative",
        className ?? "",
        Boolean(error?.message) && "mb-2"
      )}
    >
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        defaultValue={type === "date" ? Date.now() : ""}
        placeholder={placeholder}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key) && type  === "number") {
            event.preventDefault()
          }
        }}
        {...field}
        {...props}
        className={clsx(
          inputClassName,
          padding ?? "py-2 px-[18px]",
          "w-full border border-[rgba(58,53,65,0.23)] lg:text-base",
          "focus:outline-none focus:shadow-outline active:shadow-outline  text-sm",
          inputStyle,
          field.value && "active",
          type === "date" && "active",
          type === "password" && "!pr-10",
          Boolean(error?.message) && "!border-red"
        )}
      />

      <label className={clsx("label")} htmlFor={name}>
        <div className="text">{label}</div>
      </label>

      {Boolean(error?.message) && (
        <small className="text-red absolute -bottom-5 left-0">*{error?.message}</small>
      )}

      {type === "password" && (
        <button
          type="button"
          className={clsx("absolute right-5 top-4")}
          // className={clsx("justify-end bg-red !w-full flex flex-end")}
          onClick={passwordToggle}
          id="show-password"
          value=""
        >
          {showPassword ? (
            <AiOutlineEyeInvisible className="cursor-pointer" />
          ) : (
            <AiOutlineEye className="cursor-pointer" />
          )}
        </button>
      )}
    </div>
  )
}
