import { FlexBox } from "@components/wrapper"
import { Dialog, Transition } from "@headlessui/react"
import { emptyFunction } from "@lib/functions"
import clsx from "clsx"
import { Fragment, ReactNode } from "react"
import { FaTimes } from "react-icons/fa"

export interface ModalProps {
  show: boolean
  onClose: () => void
  contentClassName?: string
  contentPadding?: string
  backdropClassName?: string
  closeButton?: boolean
  customCloseButton?: ReactNode
  children?: any
  zIndex?: string
}

export const Modal = ({
  show = false,
  onClose,
  contentPadding = "p-5",
  backdropClassName,
  contentClassName,
  customCloseButton,
  children,
  closeButton,
  zIndex,
}: ModalProps) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-0 z-[1000] overflow-y-auto", zIndex ?? "z-[1000]")}
        onClose={onClose ?? emptyFunction}
      >
        <FlexBox className="min-h-screen px-4" justify="center" align="center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={clsx("fixed inset-0 bg-backdrop", backdropClassName)} />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                "inline-block bg-white rounded-lg z-[1000] min-h-10 min-w-[30vw] lg:max-w-[80vw] max-w-[calc(100vw-30px)] max-h-[calc(100vh-30px)] relative",
                contentClassName,
                contentPadding
              )}
            >
              {customCloseButton ?? (
                <button
                  className={clsx(
                    "flex top-2.5 right-2.5 absolute z-[888] align-end",
                    !closeButton && "lg:hidden"
                  )}
                  onClick={onClose}
                >
                  <FaTimes />
                </button>
              )}
              {children}
            </div>
          </Transition.Child>
        </FlexBox>
      </Dialog>
    </Transition>
  )
}
