import React from "react"
import clsx from "clsx"

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  headingLevel: "h1" | "h2" | "h3" | "card-title"
}

export const Heading: React.FC<HeadingProps> = ({
  headingLevel,
  className,
  children,
  ...props
}) => {
  switch (headingLevel) {
    case "h1":
      return (
        <h1
          className={clsx(
            "font-semibold lg:text-4xl lg:leading-[52px]",
            "text-2xl leading-[36px]",
            className
          )}
          {...props}
        >
          {children}
        </h1>
      )
    case "h2":
      return (
        <h2
          className={clsx(
            "font-semibold lg:text-[28px] lg:leading-[36px]",
            "text-lg leading-[24px]",
            className
          )}
          {...props}
        >
          {children}
        </h2>
      )
    case "h3":
      return (
        <h3 className={className} {...props}>
          {children}
        </h3>
      )
    case "card-title":
      return (
        <div {...props} className={clsx("font-semibold sm:text-lg", "text-sm", className)}>
          {children}
        </div>
      )
    default:
      return (
        <div className={className} {...props}>
          {children}
        </div>
      )
  }
}
