import React from "react"
import { ButtonType } from "./types"
import clsx from "clsx"
import Link from "next/link"

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: ButtonType
  isExternal?: boolean
  isNewTab?: boolean
  customPadding?: string
  customFontSize?: string
}

export const LinkButton = ({
  variant = "solid",
  isExternal = false,
  isNewTab = false,
  href = "",
  customPadding = "px-5 py-2",
  customFontSize = "text-sm lg:text-base",
  ...props
}: LinkButtonProps) => {
  let variantStyle = ""

  switch (true) {
    case variant === "solid":
      variantStyle = "text-white bg-green hover:text-white"
      break
    case variant === "new-blue":
      variantStyle = "text-white bg-new-blue hover:text-white"
      break
    case variant === "solid-white":
      variantStyle = "text-black bg-white hover:text-black"
      break
    case variant === "transparent":
      variantStyle = "text-green bg-transparent border-green lg:border border-2"
      break
    case variant === "transparent-black":
      variantStyle = "text-black bg-transparent border-black border hover:text-black"
      break
    default:
      break
  }

  const styles = clsx(
    "relative flex items-center justify-center rounded font-medium hover:shadow-[0_12px_32px_0px_rgba(0,0,0,0.2)] text-center",
    variantStyle,
    customPadding,
    customFontSize,
    props.className
  )

  return (
    <React.Fragment>
      {isExternal ? (
        <a
          {...props}
          href={href}
          className={styles}
          target={isNewTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
        />
      ) : (
        <Link href={href} {...props} className={styles} />
      )}
    </React.Fragment>
  )
}
